import { faEnvelope, faHandHoldingDollar, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';

function Footer() {
    return (
        <>
            <Container className=' bg-light py-5 mb-5 text-center'>
                <Row className='justify-content-md-evenly align-items-center'>
                    <Col sm={12} md={2}>
                        <h3 className="mb-3"><span className="text-success"></span></h3>
                        <a href="https://openspaceinstitute.org/support-our-work/citizenaction" target="_blank"
                            className="btn btn-warning btn-lg fs-2" rel="noopener noreferrer">DONATE</a>
                        <p><a href="https://www.openspaceinstitute.org/" rel="noopener noreferrer" target="_blank"><img src={`${process.env.PUBLIC_URL}/assets/SNMG-DONATE-QR-Code.png`} className="img-fluid artist-image mb-4" alt="Open Space Institute, Inc. Donate Page" /></a></p>

                    </Col>
                    <Col sm={12} md={8}>
                        <h4>Fiscal Sponsor</h4>
                        <div id="fiscally-sponsored">
                            <p className="lead"><a className="text-warning" href="https://www.openspaceinstitute.org/"
                                rel="noopener noreferrer" target="_blank">Open Space Institute, Inc.</a> is a
                                nonprofit public charity that serves as our fiscal sponsor. We are part of their Citizen
                                Action Program. Open Space Institute, Inc. is exempt from federal income tax under
                                Sections 501(c)(3) and 509(a)(1) of the Internal Revenue Code.</p>
                        </div>
                    </Col>
                </Row>
            </Container>

            <footer id="contact" className="mt-auto text-white-50 text-center">
                <Container>
                    <Row className='align-items-center'>
                        <Col sm={12} md={6}>
                            <address className="d-block mb-0 text-white">330 St Nicholas Ave, New York, NY 10027</address>
                            <p className="text-white"><em>between W126<sup>th</sup> &amp; W127<sup>th</sup></em><br /><a
                                href="https://goo.gl/maps/GJWb6RoRyUJ412J59" title="Google Map directions"
                                rel="noopener noreferrer" target="_self">Google Map</a><br />Latitude: 40.81181, Longitude:
                                -73.95172</p>
                        </Col>
                        <Col sm={12} md={6}>
                            <ul className="list-inline text-white">
                                <li className="list-inline-item"><a href="https://goo.gl/maps/oqQ45DGceaxbsoBj7"
                                    rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faLocationDot} size="lg" /></a></li>
                                <li className="list-inline-item"><a href="mailto:stnickgarden@gmail.com"
                                    rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faEnvelope} size="lg" /></a></li>
                                <li className="list-inline-item"><a href="https://openspaceinstitute.org/support-our-work/citizenaction"
                                    rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faHandHoldingDollar} size="lg" /></a></li>

                                <li className="list-inline-item"><a href="https://www.facebook.com/stnicksgarden"
                                    rel="noopener noreferrer" target="_blank"><i className="fab fa-facebook-f fa-lg"></i></a>
                                </li>
                                <li className="list-inline-item"><a href="https://www.instagram.com/stnicksgarden/"
                                    rel="noopener noreferrer" target="_blank"><i className="fab fa-instagram fa-lg"></i></a>
                                </li>
                                <li className="list-inline-item"><a href="https://flic.kr/s/aHsmVcUy5r" rel="noopener noreferrer"
                                    target="_blank"><i className="fab fa-flickr fa-lg"></i></a></li>
                                <li className="list-inline-item"><a href="https://twitter.com/stnicksgarden"
                                    rel="noopener noreferrer" target="_blank"><i className="fab fa-twitter fa-lg"></i></a></li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </>
    )
}

export default Footer;