import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

export const Events = () => (
<Container fluid className="p-0">
    <Container className='mb-5 text-center p-0' style={{ maxWidth: '90%' }}>
        <Row
            id="events"
            className="py-4 mb-5 rounded"
        >
            <Col xs={12} className="mb-4">
                <h2 className="upcoming-events-title">
                    <a
                        href="https://www.facebook.com/stnicksgarden/events/"
                        title="Events"
                        rel="noopener noreferrer"
                        target="_self"
                        className="text-success"
                    >
                        Upcoming Events
                    </a>
                </h2>
                <p className="events-description">
                    Explore our upcoming events! Check the dates on our Google Calendar below, and click on an event to learn more about the exciting programs we have for adults, children, and families.
                </p>
            </Col>
            <Col xs={12}>
                <iframe
                    src="https://calendar.google.com/calendar/embed?src=stnickgarden%40gmail.com&ctz=America%2FNew_York"
                    className="events-calendar"
                    width="100%"
                    height="700"
                    frameBorder="0"
                    title="Garden Event Calendar"
                ></iframe>
            </Col>
            <Col xs={12} className="mt-3">
                <ul className="list-inline d-flex flex-column flex-md-row justify-content-md-center align-items-center">
                    <li className="list-inline-item mb-3 mb-md-0 w-100 w-md-auto">
                        <Button
                            href="https://www.facebook.com/stnicksgarden/"
                            rel="noopener noreferrer"
                            target="_blank"
                            variant="success"
                            className="event-button"
                        >
                            Join an Event
                        </Button>
                    </li>
                    <li className="list-inline-item mb-3 mb-md-0 w-100 w-md-auto">
                        <Button
                            href="https://calendar.google.com/calendar/embed?src=stnickgarden%40gmail.com&ctz=America%2FNew_York"
                            rel="noopener noreferrer"
                            target="_blank"
                            variant="info"
                            className="event-button"
                        >
                            Google Calendar
                        </Button>
                    </li>
                    <li className="list-inline-item w-100 w-md-auto">
                        <Button
                            href="https://groups.google.com/forum/?fromgroups#!forum/st-nicks-miracle-garden"
                            rel="noopener noreferrer"
                            target="_blank"
                            variant="warning"
                            className="event-button"
                        >
                            Google Group
                        </Button>
                    </li>
                </ul>
            </Col>
        </Row>
    </Container>
</Container>
);
