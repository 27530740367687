import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

export const Donate = () => (
    <Container fluid>
        <Container className="bg-5 text-center">
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta2/css/all.min.css"
                integrity="sha512-YWzhKL2whUzgiheMoBFwW8CKV4qpHQAEuvilg9FAn5VJUDwKZZxkJNuGM4XkWuk94WCrrwslk8yWNGmY1EduTA=="
                crossOrigin="anonymous" referrerPolicy="no-referrer" />
            <Row className="cover-container container d-flex p-sm-0 p-md-3 flex-column">
                <main id="home" className="container mb-5">
                    <Row className="container mb-4 bg-light py-5 text-center">
                        <Col className="row justify-content-md-center align-items-center">
                            <Col md={5}>
                                <p> <a className="nav-link" href="https://openspaceinstitute.org/support-our-work/citizenaction"
                                    rel="noopener noreferrer" target="_blank"><span
                                        className="badge bg-warning fs-1">Donate</span></a></p>
                            </Col>
                            <Col md={7}>
                                <p className="lead">Your contribution supports a kaleidoscope of initiatives that make the St
                                    Nicholas Miracle Garden more than just a green space - it's a thriving center of community,
                                    culture, and sustainability.</p>
                                <p>Join us in making a difference by donating today!</p>
                            </Col>
                        </Col>
                        <h2 className="my-4">Why Support Our Community Garden?</h2>
                        <Row className="row row-cols-1 row-cols-md-3 g-4 mb-5">
                            <Col md={6}>
                                <h3>Empowering Youth</h3>
                                <p>Through our Youth Gardener Program, we provide a nurturing
                                    environment for the younger generation to learn about horticulture, teamwork, and
                                    responsibility. Your donation supports educational materials, tools, and mentorship for our
                                    aspiring young gardeners.</p>
                            </Col>
                            <Col md={6}>
                                <h3>Fresh Produce for All</h3>
                                <p>Our Farm Stand and Community Supported Agriculture
                                    (CSA) programs provide Harlem residents with fresh, locally grown produce. Your contribution
                                    helps us maintain these programs, ensuring our community has access to nutritious and
                                    affordable food options.</p>
                            </Col>
                            <Col md={6}>
                                <h3>Live Music and Performances</h3>
                                <p>Immerse yourself in the vibrant culture of
                                    Harlem by supporting our live music and performance events held in the garden. Your donation
                                    helps us bring talented artists to our community, creating an inclusive space for everyone
                                    to enjoy.</p>
                            </Col>
                            <Col md={6}>
                                <h3>Upcycle Fashion Shows</h3>
                                <p>We're not just about plants; we celebrate creativity
                                    too! Our upcycle fashion shows promote sustainable fashion and artistic expression. Your
                                    support enables us to organize these unique events that inspire environmental consciousness
                                    through style.</p>
                            </Col>
                            <Col md={6}>
                                <h3>Compost Project Programs</h3>
                                <p>Join us in the cycle of sustainability by
                                    supporting our compost project programs. Your donation helps us expand our composting
                                    initiatives, reducing waste and enriching our soil for even more bountiful harvests.</p>
                            </Col>
                        </Row>

                        <p>Stay tuned to our <a href="https://www.instagram.com/stnicksgarden/" rel="noopener noreferrer"
                            target="_blank"><i className="fab fa-instagram fa-lg"></i></a>
                            <a href="https://www.facebook.com/stnicksgarden" rel="noopener noreferrer" target="_blank">
                                <i className="fab fa-facebook-f fa-lg"></i></a> for more information and updates.</p>

                        {/* <p>Stay tuned to our <a href="https://www.instagram.com/stnicksgarden/" rel="noopener noreferrer"
                            target="_blank"><i className="fab fa-instagram fa-lg"></i></a> <a
                                href="https://www.facebook.com/stnicksgarden" rel="noopener noreferrer" target="_blank"><i
                                    className="fab fa-facebook-f fa-lg"></i></a> for more information and updates.</p> */}
                    </Row>

                    <Row className=" container mb-4 bg-light py-5 text-center">
                        <h2 className=" my-4">Your Donation - Growing More Than Gardens</h2>
                        <Row className="row justify-content-md-center">
                            <Col md={6}>
                                <h3>Youth Empowerment</h3>
                                <p>Support the next generation of gardeners and leaders by
                                    funding
                                    tools, educational workshops, and mentorship for our Youth Gardener Program.</p>
                            </Col>
                            <Col md={6}>
                                <h3>Fresh Food Access</h3>
                                <p>Contribute to our Farm Stand and CSA programs, ensuring
                                    our
                                    community has continuous access to fresh, locally sourced produce.</p>
                            </Col>
                            <Col md={6}>
                                <h3>Cultural Enrichment</h3>
                                <p>Help us organize vibrant live music, performances, and
                                    upcycle
                                    fashion shows that unite the community through creativity and celebration.</p>
                            </Col>
                            <Col md={6}>
                                <h3>Sustainability Initiatives:</h3>
                                <p>Back our compost project programs to foster a
                                    more
                                    sustainable Harlem, reducing waste and enhancing our garden's ecological impact.</p>
                            </Col>
                        </Row>
                    </Row>

                    <Row className="container mb-4 bg-light py-5 text-center">
                        <h2 className="my-4">Donate Now to Cultivate Unity and Joy in Harlem!</h2>
                        <p className="lead">Your contribution supports a kaleidoscope of initiatives that make the Heart of Harlem
                            Community Garden more
                            than just a green space - it's a thriving center of community, culture, and sustainability.</p>
                        <p><a className="nav-link" href="https://openspaceinstitute.org/support-our-work/citizenaction"
                            rel="noopener noreferrer" target="_blank"><span className="badge bg-warning fs-1">Donate</span></a>
                        </p>
                        <p>Thank you for helping us cultivate unity, sustainability, and joy in the heart of Harlem!</p>
                    </Row>
                </main>
            </Row>
        </Container>
    </Container>
)