import './App.css';
import './styles.css'; // Import the CSS file

import React, { Component} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Home } from './Pages/Home';
import { About } from './Pages/About';
import { Projects } from './Pages/Projects';
import { Events } from './Pages/Events';
import { Contact } from './Pages/Contact';
import { Donate } from './Pages/Donate';
import { Greenhouse } from './Pages/Greenhouse';
import { Composting } from './Pages/Composting';
import {  Layout } from './Components/Layout';
import { NavigationBar } from './Components/NavigationBar';
import { Jumbotron } from './Components/Jumbotron';
import  Footer from './Components/Footer';


class App extends Component{
  render(){
    return (
      <React.Fragment>
        <NavigationBar />
        <Jumbotron />
          <Layout>
           <Router>
              <Routes>
                <Route exact path='/' element={<Home/>} />
                <Route path='/about' element={<About/>} />
                <Route path='/projects' element={<Projects/>} />
                <Route path='/events' element={<Events/>} />
                <Route path='/contact' element={<Contact/>} />
                <Route path='/composting' element={<Composting/>} />
                <Route path='/greenhouse' element={<Greenhouse/>} />
                <Route path='/donate' element={<Donate/>} />
              </Routes>
            </Router>
         </Layout>
         <Footer />
      </React.Fragment>
     );
  }
}

export default App;
