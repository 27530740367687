import React from 'react';
import { Container } from 'react-bootstrap';


export const Jumbotron = () => (
    <Container fluid className="text-dark">
        <Container className='mb-5 text-white text-center'>
            <h1 className="display-3">Saint Nicholas Miracle Garden</h1>
            <h4 className="display-4 p-4 mb-5 bg-light text-dark">live, learn &amp; grow together.</h4>
            <p className="lead text-white">We are a reactivated community garden in Central Harlem. The garden's compact
                size and
                dense urban context allow us to promote vertical urban gardening and test innovative techniques to
                maximize space and resources.</p>
        </Container>
    </Container>
)