import { faEnvelope, faMapMarkerAlt, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

export const Contact = () => (
  <Container className='py-5 my-5 rounded shadow contact-container'>
    {/* Contact Information Section */}
    <Row className='justify-content-center'>
      <Col xs={12} md={4} className="d-flex align-items-center mb-4 mb-md-0">
        <div className="d-flex align-items-center">
          <div className="flex-shrink-0">
            <FontAwesomeIcon icon={faEnvelope} size="3x" className="contact-icon" />
          </div>
          <div className="flex-grow-1 ms-3">
            <h4 className="contact-heading">Email:</h4>
            <a href="mailto:stnickgarden@gmail.com" className="contact-link">stnickgarden@gmail.com</a>
          </div>
        </div>
      </Col>
      <Col xs={12} md={4} className="d-flex align-items-center mb-4 mb-md-0 border-start">
        <div className="d-flex align-items-center">
          <div className="flex-shrink-0">
            <FontAwesomeIcon icon={faPhone} size="3x" className="contact-icon" />
          </div>
          <div className="flex-grow-1 ms-3">
            <h4 className="contact-heading">Phone:</h4>
            <a href="tel:+1-347-391-7189" className="contact-link">(347) 391-7189</a>
          </div>
        </div>
      </Col>
      <Col xs={12} md={4} className="d-flex align-items-center border-start">
        <div className="d-flex align-items-center">
          <div className="flex-shrink-0">
            <FontAwesomeIcon icon={faMapMarkerAlt} size="2x" className="contact-icon" />
          </div>
          <div className="flex-grow-1 ms-3">
            <h4 className="contact-heading">Location:</h4>
            <p className="contact-address">330 St Nicholas Ave, New York, New York 10032</p>
          </div>
        </div>
      </Col>
    </Row>
  </Container>
);
