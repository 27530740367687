import React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import logo from '../img/snmg-logo.png';


export const NavigationBar = () => (
  <Navbar expand="lg" className='justify-content-center'>
    <Container>
      <Navbar.Brand href="/"><Image src={logo} /></Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ms-auto align-items-center nav-masthead ">
          <Nav.Item><Nav.Link href="/">Home</Nav.Link></Nav.Item>
          <Nav.Item><Nav.Link href="/about">About</Nav.Link></Nav.Item>
          <Nav.Item><Nav.Link href="/events">Events</Nav.Link></Nav.Item>
          <Nav.Item><Nav.Link href="/composting">Composting</Nav.Link></Nav.Item>
          <Nav.Item><Nav.Link href="/greenhouse">Greenhouse</Nav.Link></Nav.Item>
          <Nav.Item><Nav.Link href="/projects">Projects</Nav.Link></Nav.Item>
          <Nav.Item><Nav.Link href="/contact">Contact</Nav.Link></Nav.Item>
          <Nav.Item><Nav.Link href="/donate" className='badge bg-warning text-white fs-6'>Donate</Nav.Link></Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
)