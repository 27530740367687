import React from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandsHelping, faSeedling } from '@fortawesome/free-solid-svg-icons';

export const Home = () => (
    <>
        {/* Our Mission Section */}
        <Container className='text-center py-5 my-5 rounded shadow home-container'>
            <Row className='justify-content-center align-items-center'>
                <Col xs={12} md={10} lg={10}>
                    <h2 className="text-success home-heading">Our Mission</h2>
                    <p className="home-text">
                        The garden promotes environmental awareness and serves as a growing educational resource and
                        community gardening space. In addition, it addresses food insecurity by providing food access
                        programming and workshops on healthy eating and sustainable lifestyles. By fostering a sense of
                        community and engaging in hands-on learning, we strive to make a positive impact on both the
                        environment and the well-being of our community members. Our initiatives aim to bring people
                        together, support local ecosystems, and provide valuable resources for sustainable living.
                    </p>
                </Col>
            </Row>
        </Container>

        {/* Welcome Section */}
        <Container className='text-center py-5 my-5 rounded shadow home-container'>
            <p className="text-muted home-text">
                Welcome to our community garden! We are so pleased to have you join us in our efforts to promote environmental sustainability and
                create a space where people can come together to enjoy nature and learn more about gardening. We look forward to seeing you around the garden and
                encourage you to participate in our workshops, events, and activities. Please let us know if you have any questions or need assistance. Thank you
                for being part of our community!
            </p>
            <ul className="list-inline mt-4">
                <li className="list-inline-item mb-3 mb-md-0">
                    <Button href="mailto:stnickgarden@gmail.com" variant="warning" className="home-button">Email Us</Button>
                </li>
                <li className="list-inline-item mb-3 mb-md-0">
                    <Button href="https://www.facebook.com/stnicksgarden/events" variant="warning" className="home-button" rel="noopener noreferrer" target="_blank">Join an event!</Button>
                </li>
            </ul>
        </Container>

        {/* Garden Season Section */}
        <Container className='text-center py-5 my-5 rounded shadow home-container'>
            <Row className='justify-content-center align-items-center'>
                <Col xs={12} md={4} className="mb-4 mb-md-0">
                    <FontAwesomeIcon icon={faSeedling} size="8x" className='text-success garden-icon' />
                    <h3 className="text-success garden-heading">Garden <br />April - October</h3>
                </Col>
                <Col xs={12} md={6}>
                    <p className="garden-text">
                        Welcome to St Nicholas Miracle Garden! The garden season runs from April to
                        October with regular open hours on Thursdays and Sundays starting in June. Check out our
                        calendar below for upcoming events.
                    </p>
                </Col>
            </Row>
        </Container>

        {/* Volunteer Section */}
        <Container className='text-center py-5 my-5 rounded shadow home-container'>
            <Row className='justify-content-center align-items-center'>
                <Col xs={12} md={3} className="mb-4 mb-md-0">
                    <FontAwesomeIcon icon={faHandsHelping} size="8x" className='text-success garden-icon' />
                    <h3 className="text-success volunteer-heading">Volunteer</h3>
                </Col>
                <Col xs={12} md={6}>
                    <p className="volunteer-text">
                        We invite you to join us at our upcoming events and volunteer opportunities.
                        It's a great chance to meet new people, get active, and positively contribute to your local area.
                    </p>
                    <p className="volunteer-text">
                        We hope to see you at the garden soon!
                    </p>
                    <h5 className="volunteer-date">Saturday, August 24 <br />9:00am - 12:00pm</h5>
                    <h5 className="volunteer-date">Tuesday, August 27 <br />4:00pm - 6:00pm</h5>
                    <ul className="list-inline mt-4">
                        <li className="list-inline-item">
                            <Button href="https://forms.gle/ZKb4aKfZGyysD2h39" variant="warning" className="home-button" target="_blank" rel="noopener noreferrer">Join the volunteering list</Button>
                        </li>
                    </ul>
                </Col>
            </Row>
        </Container>

        {/* Artist and Educators Call Section */}
        <Container className='text-center py-5 my-5 rounded shadow home-container'>
            <Row className='justify-content-center align-items-center'>
                <Col xs={12} md={4} className="mb-4 mb-md-0">
                    <img src={`${process.env.PUBLIC_URL}/assets/projects/open-call-artist-educators.png`} className="img-fluid artist-image mb-4" alt="Open Call of Artist and Educators" />
                </Col>
                <Col xs={12} md={6}>
                    <p className="artist-text">
                        At St Nicholas Miracle Garden, we are thrilled to launch an exciting journey
                        this year of transforming our garden space into a vibrant haven. Our upcoming beautification projects aim
                        to enhance the garden's beauty and increase its functionality. We are seeking talented local
                        artists, creatives, and exterior designers to join us.
                    </p>
                    <ul className="list-inline mt-4">
                        <li className="list-inline-item mb-3 mb-md-0">
                            <Button href="/projects" variant="warning" className="home-button">Learn More</Button>
                        </li>
                    </ul>
                </Col>
            </Row>
        </Container>
    </>
);
