import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';

export const Greenhouse = () => (
    <>
        {/* Community Benefit Section */}
        <Container className='py-5 my-5 rounded shadow greenhouse-container'>
            <Row className='justify-content-center align-items-center'>
                <Col xs={12} md={5} className="d-flex flex-column justify-content-center text-center text-md-start mb-4 mb-md-0">
                    <h3 className="greenhouse-heading">Community Benefit</h3>
                </Col>
                <Col xs={12} md={7} className="d-flex flex-column justify-content-center text-center text-md-start">
                    <p className="greenhouse-text">
                        The greenhouse will serve as a central hub, providing seedlings to community gardens and fostering community pride.
                        It will create a beautiful, vibrant space for everyone to enjoy. Additionally, the greenhouse will offer educational
                        opportunities, serving as an ideal environment for learning about gardening and plant care. This initiative aims to
                        enrich the community by promoting sustainable practices and enhancing local green spaces.
                    </p>
                </Col>
            </Row>
        </Container>

        {/* Project Goals Section */}
        <Container className='py-5 my-5 rounded shadow greenhouse-container'>
            <Row className='justify-content-center align-items-center'>
                <Col xs={12} md={5} className="d-flex flex-column justify-content-center text-center text-md-start mb-4 mb-md-0">
                    <h3 className="greenhouse-heading">Project Goals</h3>
                </Col>
                <Col xs={12} md={7} className="d-flex flex-column justify-content-center text-center text-md-start">
                    <p className="greenhouse-text">
                        The goals for the greenhouse include the following:
                    </p>
                    <ul className="greenhouse-list">
                        <li>Providing fresh, local produce in Central Harlem.</li>
                        <li>Offering educational opportunities to youth on urban agriculture and sustainability.</li>
                        <li>Creating a space for community members to connect and collaborate on projects.</li>
                        <li>Providing a source of outdoor activity and recreation.</li>
                        <li>Take advantage of natural resources by using solar energy and rain catchers.</li>
                    </ul>
                    <p className="greenhouse-text">
                        Additionally, the greenhouse could serve as a hub for local community gardens to grow or select their seedlings,
                        further contributing to the local shared economy.
                    </p>
                </Col>
            </Row>
        </Container>

        <Container className='py-5 my-5 rounded shadow greenhouse-container'>
            <Row className='justify-content-center align-items-center'>
                <Col xs={12} md={5} className="d-flex flex-column justify-content-center text-center text-md-start mb-4 mb-md-0">
                    <h3 className="greenhouse-heading">Stories</h3>
                </Col>
                <Col xs={12} md={7} className="d-flex flex-column justify-content-center text-center text-md-start">
                    <p className="greenhouse-text">Our greenhouse project team builds and maintains the year-round structure, designs the interior with student architects, and sources materials from local hardware stores. We manage seed-to-seedling production, focusing on vertical urban gardening techniques to maximize space. Our team also tests the greenhouse environment by growing crops like cowpeas and squash and assists local farms by providing seedlings to recover from storm damage. We coordinate regular volunteer days and membership events to ensure smooth operations.</p>
                    <a href="https://www.uptowngoodfood.com/greenhouse" target="_blank"
                        className="btn btn-warning btn-lg fs-2" rel="noopener noreferrer">Read More</a>

                </Col>
            </Row>
        </Container>
    </>
);
