import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';

import ModalProjects from '../Components/ModalProjects';


export const Projects = () => (
    <Container fluid>
        <Container className='mb-5 text-center'>
            <Container className="container mb-4 bg-light py-5 text-center">
                <Row className="row justify-content-md-center align-items-center">
                    <Col md={5}>
                        <FontAwesomeIcon icon={faUsers} size="5x" className='text-success' />
                        <h3 className="mb-3">
                            <a href="mailto:stnickgarden@gmail.com" title="Garden" target="_self">
                                <span className="text-success">Projects</span>
                            </a>
                        </h3>
                    </Col>
                    <Col md={7}>
                        <p>Welcome to St Nicholas Miracle Garden, where we're thrilled to launch an
                            exciting journey of
                            transforming our garden space into a vibrant haven. Our upcoming beautification projects aim
                            to change the garden's beauty and functionality. We're seeking talented local artists,
                            creatives, and exterior designers to join us.</p>
                        <ul className="list-inline">
                            <li className="list-inline-item"><a href="https://forms.gle/2QWztGEzSzjVPMv2A"
                                rel="noopener noreferrer" target="_blank">Learn More</a></li>
                        </ul>
                    </Col>
                </Row>
                <h4 className="my-4">Opportunities for Artists and Designers</h4>
                <Row className="row row-cols-1 row-cols-md-3 g-4 mb-5">
                    <Col className="col">
                        <Col className="card h-100">
                            <Col className="card-header">
                                Unavailable
                            </Col>
                            <img src={`${process.env.PUBLIC_URL}/assets/garden_beautification/shed.png`} alt='metal shed' className="img-fluid" />
                            <Col className="card-body">
                                <h5 className="card-title">Painting the Metal Shed</h5>
                                <p className="card-text">We're looking for artists who can transform structures into
                                    eye-catching art pieces. We want to hear from you if you have experience in mural
                                    painting or outdoor art.</p>
                                <p><a href="https://untappedcities.com/2018/10/01/59-nyc-outdoor-art-installations-not-to-miss-in-october-2018/60/"
                                    target="_blank" rel="noopener noreferrer">Inspiration Project</a></p>
                            </Col>
                            <Col className="card-footer">
                                <small className="text-body-secondary">9ft W x 10ft H x 9ft L</small>
                            </Col>
                        </Col>
                    </Col>
                    <Col className="col">
                        <Col className="card h-100">
                            <Col className="card-header">
                                Available
                            </Col>
                            <img src={`${process.env.PUBLIC_URL}/assets/garden_beautification/wooden-table.png`} alt='measurements of wooden table' className="img-fluid" />
                            <Col className="card-body">
                                <h5 className="card-title">Painting Wooden Picnic Table</h5>
                                <p className="card-text">We're seeking designers who can breathe new life into our picnic
                                    area with the help of local youth. Your artistic touch can create a welcoming and
                                    aesthetically pleasing space for our community.</p>
                            </Col>
                            <Col className="card-footer">
                                {/* <!-- Button trigger modal --> */}
                                <ModalProjects imageSrc={`${process.env.PUBLIC_URL}/assets/garden_beautification/wooden-table.png`}
                                    altText={"measurements of wooden table"}
                                    pathText={"Painting Wooden Picnic Table"} />
                            </Col>
                        </Col>
                    </Col>
                    <Col className="col">
                        <Col className="card h-100">
                            <Col className="card-header">
                                Available
                            </Col>
                            <img src={`${process.env.PUBLIC_URL}/assets/garden_beautification/wooden-bench.png`} alt='Restoration and Painting Wooden Benches' className="img-fluid" />
                            <Col className="card-body">
                                <h5 className="card-title">Restoration and Painting Wooden Benches</h5>
                                <p className="card-text">We're seeking designers who can restore benches and provide an
                                    artistic touch to pair with the picnic table, also in conjunction with local youth.
                                </p>
                            </Col>
                            <Col className="card-footer">
                                {/* <!-- Button trigger modal --> */}
                                <ModalProjects
                                    imageSrc={`${process.env.PUBLIC_URL}/assets/garden_beautification/wooden-bench.png`}
                                    altText={"Restoration and Painting Wooden Benches"}
                                    pathText={"Restoration and Painting Wooden Benches"} />
                            </Col>
                        </Col>
                    </Col>
                    <Col className="col">
                        <Col className="card h-100">
                            <Col className="card-header">
                                Available
                            </Col>
                            <img src={`${process.env.PUBLIC_URL}/assets/garden_beautification/stadium-seating.png`} alt='Wooden Stadium Seating' className="img-fluid" />
                            <Col className="card-body">
                                <h5 className="card-title">Painting Wooden Stadium Seating</h5>
                                <p className="card-text">If you are passionate about outdoor design, consider lending your
                                    skills to renew our stadium seating. Your work will be a focal point during
                                    community events and gatherings.</p>
                            </Col>
                            <Col className="card-footer">
                                <small className="text-body-secondary">8ft W x 1ft H <br />4 panels</small>
                            </Col>
                        </Col>
                    </Col>
                    <Col className="col">
                        <Col className="card h-100">
                            <Col className="card-header">
                                Available
                            </Col>
                            <img src={`${process.env.PUBLIC_URL}/assets/garden_beautification/fence-artwork.png`} alt='fence for artwork' className="img-fluid" />
                            <Col className="card-body">
                                <h5 className="card-title">Artwork Along the Fences</h5>
                                <p className="card-text">Bring your creativity to life by contributing to our fence art
                                    project. This is an excellent opportunity to showcase your unique style and make a
                                    lasting impact on our garden.</p>
                            </Col>
                            <Col className="card-footer">
                                <small className="text-body-secondary">18ft W x ?ft H</small>
                            </Col>
                        </Col>
                    </Col>
                    <Col className="col">
                        <Col className="card h-100">
                            <Col className="card-header">
                                Available
                            </Col>
                            <img src={`${process.env.PUBLIC_URL}/assets/garden_beautification/fence-artwork.png`} alt='fence for artwork' className="img-fluid" />
                            <Col className="card-body">
                                <h5 className="card-title">Little Structures for Critters</h5>
                                <p className="card-text">Our initiative is to construct small structures for the garden's
                                    critters, such as birdhouses and bee houses. We also create kid-friendly programming
                                    centered around building houses for these critters, fostering a connection between
                                    children and the natural world.</p>
                            </Col>
                        </Col>
                    </Col>
                    <Col className="col">
                        <Col className="card h-100">
                            <Col className="card-header">
                                Completed
                            </Col>
                            <img src={`${process.env.PUBLIC_URL}/assets/garden_beautification/portable-toilet.png`} alt='Building a Portable Toilet Enclosure' className="img-fluid" />
                            <Col className="card-body">
                                <h5 className="card-title">Building a Portable Toilet Enclosure</h5>
                                <p className="card-text">Exterior designers and architects, this is your chance to
                                    contribute to the functionality and aesthetics of our garden. Please help us create
                                    a multifunctional enclosure to place around the portable toilet.</p>
                            </Col>
                            <Col className="card-footer">
                                <small className="text-body-secondary">3ft W x 7ft H</small>
                            </Col>
                        </Col>
                    </Col>
                    <Col className="col">
                        <Col className="card h-100">
                            <Col className="card-header">
                                Available
                            </Col>
                            <img src={`${process.env.PUBLIC_URL}/assets/garden_beautification/outdoor-info-banner.jpg`} alt='Garden Information Banner' className="img-fluid" />
                            <Col className="card-body">
                                <h5 className="card-title">Garden Information Outdoor Banner and Event Bulletin Board</h5>
                                <p className="card-text">Graphic designers, we invite you to showcase your talent by
                                    designing informative banners and bulletin boards. Your work will keep our community
                                    informed and engaged.</p>
                            </Col>
                            <Col className="card-footer">
                                <small className="text-body-secondary">30 in W</small>
                            </Col>
                        </Col>
                    </Col>
                </Row>
                <p>Stay tuned to our <a href="https://www.instagram.com/stnicksgarden/" rel="noopener noreferrer"
                    target="_blank"><i className="fab fa-instagram fa-lg"></i></a> <a
                        href="https://www.facebook.com/stnicksgarden" rel="noopener noreferrer" target="_blank"><i
                            className="fab fa-facebook-f fa-lg"></i></a> for more information and updates.</p>
                {/* <!-- Vertically centered modal --> */}
                <Container className="modal fade" id="projectTableModal" data-bs-backdrop="static" data-bs-keyboard="false"
                    tabindex="-1" aria-labelledby="projectTableModalLabel" aria-hidden="true">
                    <Container className="modal-dialog modal-xl modal-dialog-centered">
                        <Container className="modal-content">
                            <Container className="modal-header">
                                <h1 className="modal-title fs-5" id="projectTableModalLabel">Painting Wooden Picnic Table
                                </h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            </Container>
                            <Container className="modal-body">
                                <img src="/img/garden_beautification/wooden-table.png" className="img-fluid"
                                    alt="measurements of wooden table" />
                            </Container>
                        </Container>
                    </Container>
                </Container>
                {/* <!-- Vertically centered modal --> */}
                <Container className="modal fade" id="projectBenchModal" data-bs-backdrop="static" data-bs-keyboard="false"
                    tabindex="-1" aria-labelledby="projectBenchModalLabel" aria-hidden="true">
                    <Container className="modal-dialog modal-xl modal-dialog-centered">
                        <Container className="modal-content">
                            <Container className="modal-header">
                                <h1 className="modal-title fs-5" id="projectBenchModalLabel">Restoration and Painting Wooden
                                    Benches
                                </h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            </Container>
                            <Container className="modal-body">
                                <img src="/img/garden_beautification/wooden-bench.png" className="img-fluid"
                                    alt="measurements of wooden bench" />
                            </Container>
                        </Container>
                    </Container>
                </Container>
            </Container>

            <Container id="timeline" className="bg-light p-5 mb-5">
                <Row className="text-center">
                    <h3 className="mb-3">
                        <span className="text-success">Timeline</span>
                    </h3>
                    <Row className="row row-cols-1 row-cols-md-2 g-4">
                        <Col className="col">
                            <h4>April-May</h4>
                            <ul className="list-unstyled">
                                <li>Review artist submissions</li>
                                <li>Begin project execution</li>
                            </ul>
                        </Col>
                        <Col className="col">
                            <h4>June</h4>
                            <ul className="list-unstyled">
                                <li><b>Open Garden Day on June 1 and 2</b> <br />(rain date of June 8 and 9)</li>
                                <li>Ribbon Cutting Ceremony - We proudly unveil our exciting projects from 2023 and give
                                    you a sneak peek into what's in store for 2024</li>
                            </ul>
                        </Col>
                    </Row>
                </Row>
            </Container>

            <Container id="application" className="bg-light p-5 mb-5">
                <Row className="text-center">
                    <h3 className="mb-3">
                        <span className="text-success">How to Get Involved</span>
                    </h3>
                    <p>We want to hear from you if you're passionate about contributing to our community's aesthetic and
                        functional improvement! Please submit your portfolio and a brief statement of interest to <a
                            href="mailto:stnickgarden@gmail.com" rel="noopener noreferrer" target="_blank">Email</a>.
                    </p>
                    <p><a className="btn btn-warning btn-lg" href="https://forms.gle/ZKb4aKfZGyysD2h39"
                        target="_blank" rel="noopener noreferrer">Apply</a></p>

                    <p>Be part of the transformation! Help us turn our garden into an oasis that reflects the creativity
                        and Containerersity of our community. Together, we can create a space everyone can enjoy for years.
                    </p>
                    <p>Thank you for being a crucial part of St Nicholas Miracle Garden's exciting journey towards
                        beautification!</p>
                    <ul className="list-inline">
                        <li className="list-inline-item"><a href="https://www.facebook.com/stnicksgarden/events"
                            rel="noopener noreferrer" target="_blank">Join an event!</a></li>
                    </ul>
                </Row>
            </Container>

            <Container className="container mb-4 bg-light py-5 text-center">
                <Row className="row justify-content-md-center align-items-center">
                    <Col md={5}>
                        <FontAwesomeIcon icon={faUsers} size="5x" className='text-success' />
                        <h3 className="mb-3">
                            <a href="mailto:stnickgarden@gmail.com" title="Garden" target="_self">
                                <span className="text-success">Building Community</span>
                            </a>
                        </h3>
                    </Col>
                    <Col md={7}>
                        <p>We are searching for passionate and enthusiastic inContaineriduals to join our team at the
                            community garden!</p>
                        <p>We welcome inContaineriduals interested in learning about sustainable gardening practices
                            and
                            want to help create an enjoyable and productive space for the community.</p>
                        <p>If you are interested in joining our team, check out our available opportunities and
                            contact us. We would be happy to tell you more about the opportunity. Thank you for
                            considering joining us!</p>
                    </Col>
                </Row>
                <h4 className="my-4">Opportunities</h4>
                <Row className="row justify-content-md-center">
                    <Col className="col-md-6">
                        <ul>
                            <li><strong>Garden Co-Leader</strong> - collaborate with fellow volunteers to lead garden
                                projects, organize volunteer activities, and develop community engagement programming.
                            </li>
                            <li><strong>Treasurer/Fundraiser</strong> - securing financial resources to support our
                                community garden's growth, sustainability, and outreach initiatives.</li>
                            <li><strong>Educator / Program Coordinator</strong> - primary educators for children and
                                youth through
                                critical programs at our gardens.</li>
                            <li><strong>Grant Writer</strong> - assist in preparing grant template documents for
                                upcoming grant applications. Adapt grant documents to meet the requirements of different
                                supporter needs and portals.</li>
                        </ul>
                    </Col>
                    <Col className="col-md-6">
                        <ul>
                            <li><strong>Social Media Specialist</strong> - is responsible for creating and publishing
                                content on all social media platforms, including Facebook and Instagram, to grow an
                                audience, build garden programming awareness, and ultimately, boost community
                                supporters.</li>
                            <li><strong>Event Planner</strong> - supported garden events, discussed the events' needs,
                                and oversaw the setup, execution, and cleanup of events.</li>
                        </ul>
                    </Col>
                </Row>
                <p><a href="https://forms.gle/ZKb4aKfZGyysD2h39" target="_blank" rel="noopener noreferrer">Join our
                    volunteering team</a></p>
            </Container>
        </Container>
    </Container>
)