import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const ModalProjects = ({ imageSrc, pathText, altText}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

    return (
      <>
        <Button className="button text-body" variant="primary" onClick={handleShow}>
          Click for measurements
        </Button>

        <Modal size="xl" show={show} onHide={handleClose} backdrop="static" keyboard={false} dialogClassName="image-modal" centered>
          <Modal.Header closeButton>
            <Modal.Title>{pathText}</Modal.Title>
          </Modal.Header>
          <Modal.Body><img src={imageSrc} alt={altText} className="img-fluid" /></Modal.Body>
        </Modal>
      </>
    )
};

export default ModalProjects;